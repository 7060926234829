<template>
  <!-- start page title -->
  <div
    class="page-title-box d-sm-flex align-items-center justify-content-between"
    style="background-color: #F3F6F9;"
  >
    <h4 class="d-flex mb-sm-0">
      <div 
        v-if="items.length > 1"
        class="ms-1 me-3 my-auto"
      >
        <router-link 
          :to="previousItem.href"
        >
          <span class="hamburger-icon open">
            <span />
            <span />
            <span />
          </span>
        </router-link>
      </div>
      {{ title }}
    </h4>

    <div class="page-title-right">
      <slot name="button" />
      <ol class="breadcrumb m-0">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="breadcrumb-item"
          :class="{ active: item[0] }"
        >
          <span v-if="index == items.length-1">
            {{ item.text }}
          </span>
          <router-link 
            v-else
            :to="item.href"
          >
            {{ item.text }}
          </router-link>
        </li>
      </ol>
    </div>
  </div>
  <!-- end page title -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    previousItem() {
      const totalItem = this.items.length
      return this.items[totalItem-2] ?? null
    }
  },
};
</script>