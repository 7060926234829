<template>
  <b-modal
    id="profile-form-modal"
    v-model="showModal"
    title="Edit Profile"
    centered
    hide-footer
    @shown="initModal"
  >
    <b-form
      class="mt-2"
      @submit.prevent="onSubmit"
    >
      <b-tabs
        v-model="tabIndex"
        justified
        content-class="no-border"
        nav-class="fw-bold mb-3"
        active-nav-item-class="custom-tabs"
      >
        <b-tab
          active
          title="Change Information"
        >
          <b-form-group
            class="mb-3 required"
            label="Name"
            label-for="input-user-name"
          >
            <b-form-input
              id="input-user-name"
              v-model="form.name"
              type="text"
              placeholder="Enter name"
              :state="v$.form.name.$dirty ? (!v$.form.name.$error? null : false) : null"
            />
            <b-form-invalid-feedback
              v-if="v$.form.name.$error"
              class="d-inline"
            >
              <span
                v-for="(error, index) in v$.form.name.$errors"
                :key="index"
              >
                {{ error.$message + ' ' }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="mb-3 required"
            label="Email"
            label-for="input-email"
          >
            <b-form-input
              id="input-email"
              v-model="form.email"
              type="email"
              placeholder="Enter email"
              :state="v$.form.email.$dirty ? (!v$.form.email.$error? null : false) : null"
            />
            <b-form-invalid-feedback
              v-if="v$.form.email.$error"
              class="d-inline"
            >
              <span
                v-for="(error, index) in v$.form.email.$errors"
                :key="index"
              >
                {{ error.$message + ' ' }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-tab> 
        <b-tab title="Change Password">
          <b-form-group
            class="mb-3 required"
            label="Old Password"
            label-for="input-old-password"
          >
            <b-input-group>
              <b-form-input
                id="input-old-password"
                v-model="form.old_password"
                :type="showOldPassword ? 'text': 'password'"
                class="form-control pe-5"
                style="border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;"
                placeholder="Enter old password"
                :state="hasErrors('old_password') ? false : (v$.form.old_password.$dirty ? (!v$.form.old_password.$error? null : false) : null)"
                @input="resetErrors()"
              />
              <b-input-group-append>
                <div
                  class="d-flex cancel-search-icon h-100 px-3"
                  :class="(v$.form.old_password.$error || hasErrors('old_password')) ? 'is-invalid' : ''"
                  style="position: relative;"
                  @click="showOldPassword = !showOldPassword"
                >
                  <i
                    class="my-auto"
                    :class="showOldPassword ? 'ri-eye-off-fill': 'ri-eye-fill'"
                  />
                </div>
              </b-input-group-append>
            </b-input-group>
            <div class="float-end text-muted small mt-1">
              {{ form.old_password?.length ?? 0 }}
            </div>
            <b-form-invalid-feedback 
              v-if="hasErrors('old_password')"
              class="d-inline"
            >
              {{ getErrors("old_password") }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="v$.form.old_password.$error"
              class="d-inline"
            > 
              <span
                v-for="(error, index) in v$.form.old_password.$errors"
                :key="index"
              >
                {{ error.$message + ' ' }}
              </span>
            </b-form-invalid-feedback>
            <div 
              v-if="!v$.form.old_password.$error && !hasErrors('old_password')"
              class="text-muted mt-1"
            >
              <small>Passwords must contain at least six characters.</small>
            </div>
          </b-form-group>
          <b-form-group
            class="mb-3 required"
            label="New Password"
            label-for="input-new-password"
          >
            <b-input-group>
              <b-form-input
                id="input-new-password"
                v-model="form.new_password"
                :type="showPassword ? 'text': 'password'"
                class="form-control pe-5"
                style="border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;"
                placeholder="Enter password"
                :state="v$.form.new_password.$dirty ? (!v$.form.new_password.$error? null : false) : null"
              />
              <b-input-group-append>
                <div
                  class="d-flex cancel-search-icon h-100 px-3"
                  :class="v$.form.new_password.$error ? 'is-invalid' : ''"
                  style="position: relative;"
                  @click="showPassword = !showPassword"
                >
                  <i
                    class="my-auto"
                    :class="showPassword ? 'ri-eye-off-fill': 'ri-eye-fill'"
                  />
                </div>
              </b-input-group-append>
            </b-input-group>
            <div class="float-end text-muted small mt-1">
              {{ form.new_password?.length ?? 0 }}
            </div>
            <b-form-invalid-feedback
              v-if="v$.form.new_password.$error"
              class="d-inline"
            >
              <span
                v-for="(error, index) in v$.form.new_password.$errors"
                :key="index"
              >
                {{ error.$message + ' ' }}
              </span>
            </b-form-invalid-feedback>
            <div 
              v-if="!v$.form.new_password.$error"
              class="text-muted mt-1"
            >
              <small>Passwords must contain at least six characters.</small>
            </div>
          </b-form-group>
          <b-form-group
            class="mb-3 required"
            label="Confirm Password"
            label-for="input-password-confirmation"
          >
            <b-input-group>
              <b-form-input
                id="input-password-confirmation"
                v-model="form.new_password_confirmation"
                :type="showPasswordConfirmation ? 'text': 'password'"
                class="form-control pe-5"
                style="border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;"
                placeholder="Enter password confirmation"
                :state="v$.form.new_password_confirmation.$dirty ? (!v$.form.new_password_confirmation.$error? null : false) : null"
              />
              <b-input-group-append>
                <div
                  class="d-flex cancel-search-icon h-100 px-3"
                  style="position: relative;"                
                  :class="v$.form.new_password_confirmation.$error ? 'is-invalid' : ''"
                  @click="showPasswordConfirmation = !showPasswordConfirmation"
                >
                  <i
                    class="my-auto"
                    :class="showPasswordConfirmation ? 'ri-eye-off-fill': 'ri-eye-fill'"
                  />
                </div>
              </b-input-group-append>
            </b-input-group>
            <div class="float-end text-muted small mt-1">
              {{ form.new_password_confirmation?.length ?? 0 }}
            </div>
            <b-form-invalid-feedback
              v-if="v$.form.new_password_confirmation.$error"
              class="d-inline"
            >
              <span
                v-for="(error, index) in v$.form.new_password_confirmation.$errors"
                :key="index"
              >
                {{ error.$message + ' ' }}
              </span>
            </b-form-invalid-feedback>
            <div
              v-if="!v$.form.new_password_confirmation.$error"
              class="text-muted mt-1"
            >
              <small>Passwords must contain at least six characters.</small>
            </div>
          </b-form-group>
        </b-tab>
      </b-tabs>

      <div class="d-flex justify-content-between gap-2 mt-4">
        <b-button
          variant="outline-danger"
          @click="resetForm"
        >
          Reset
        </b-button>
        <b-button
          type="submit"
          variant="primary"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { minLength, sameAs, required, helpers, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useToast } from 'vue-toastification';
import ToastDefault from "@/components/ToastDefault.vue";

export default {
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast();
    return { 
      toast,
      v$: useVuelidate() 
    };
  },
  validations() {
    if (!this.tabIndex) {
      // Edit user information
      return {
        form: {
          name: {
            required: helpers.withMessage("Name field is required.", required)
          },
          email: {
            required: helpers.withMessage("Email field is required.", required),
            email: helpers.withMessage("Please enter a valid email address.", email)
          },
          old_password: {},
          new_password: {},
          new_password_confirmation: {}
        },
      }
    } 
    return {
      form: {
        name: {},
        email: {},
        old_password: {
          required: helpers.withMessage("Old password field is required.", required),
          minLength: helpers.withMessage("Passwords must contain at least six characters.", minLength(6))
        },
        new_password: {
          required: helpers.withMessage("New password field is required.", required),
          minLength: helpers.withMessage("Passwords must contain at least six characters.", minLength(6))
        },
        new_password_confirmation: {
          required: helpers.withMessage("Confirm password field is required.", required),
          minLength: helpers.withMessage("Passwords must contain at least six characters.", minLength(6)),
          sameAsPassword: helpers.withMessage("Password doesn't match.", sameAs(this.form.new_password))
        },
      },
    }  
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        old_password: null,
        new_password: null,
        new_password_confirmation: null,
      },
      showModal: false,
      showOldPassword: false,
      showPassword: false,
      showPasswordConfirmation: false,
      tabIndex: 0,
      errors: {}
    };
  },
  watch: {
    tabIndex: {
      immediate: true,
      handler(val) {
        if(val) {
          this.form.name = this.profile.name
          this.form.email = this.profile.email
        }
      },
    },
  },
  methods: {
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return
      }
      this.submitForm();
    },
    resetForm() {
      this.v$.$reset()
      this.resetErrors()
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.profile[key];
      });
      this.tabIndex = 0
      this.showOldPassword = false
      this.showPassword = false
      this.showPasswordConfirmation = false
    },
    async submitForm() {
      try {
        if (!this.tabIndex) {
          await this.$api.updateProfile(this.form);
          this.closeModal();
          this.$emit('update-data', 'Edited');
          // toast
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Updated Successfully',
              icon: 'ri-check-double-line'
            },
          };
          this.toast(toastContent);
        } else {
          await this.$api.updateProfilePassword(this.form);
          this.$emit('update-data', 'Saved');
          // toast
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Saved Successfully',
              icon: 'ri-check-double-line'
            },
          };
          this.toast(toastContent);
        }
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.errors = error.response.data.errors;
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process Failed',
          },
        };
        this.toast(toastContent);
      }
    },
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },
    initModal() {
      this.resetForm();
    },
    hasErrors(key) {
      if (this.errors[key]) {
        return true;
      }
      return false;
    },
    getErrors(key) {
      if (this.hasErrors(key)) {
        return this.errors[key].join(", ");
      }
      return "";
    },
    resetErrors() {
      this.errors = {}
    }
  },
};
</script>