<template>
  <div
    class="alert alert-dismissible alert-label-icon label-arrow shadow fade show mb-0"
    :class="`alert-${variant}`"
    role="alert"
  >
    <i
      class="label-icon"
      :class="icon"
    />{{ text }}
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss=" alert"
      aria-label="Close"
    />
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    text: {
      type: String,
      default: 'Text',
    },
    icon: {
      type: String,
      default: 'ri-error-warning-line',
    },
  },
};
</script>
